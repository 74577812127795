<script>
import Base from '@backend/Base.vue';
import draggable from 'vuedraggable'
import BOGen from '@helper/BOGen';
export default {
    extends: Base,
    components: {
            draggable,
        },
    data() {
        return {
            Name: "ProjectContact",
            contact: {},
        }
    },
    computed: {
        
    },
    mounted() {
        this.$set(this.$root, 'page', this)
        this.refreshData()
    },
    methods: { 
        submitForm(e, callback){
          if(e&&e.btnLoading()) return;
          this.contact.type="editContact"
          BOGen.apirest("/"+this.Name, this.contact, (err, resp)=>{
            if(e) e.btnUnloading()
            if(err){
              if(err.status == 422){
                Object.keys(err.responseJSON).forEach((k)=>{
                  $("[error="+k+"]").html("<label class='error'>"+err.responseJSON[k][0]+"</label>")
                })
              }
              return
            }
            if(resp.error){
              if(this.errorFormCallback) return this.errorFormCallback(resp);
              if(callback) return callback(resp);
              return Gen.info(resp.message, "danger")
            }
            if(resp.success){
              if(this.successFormCallback) return this.successFormCallback(resp);
              if(callback) return callback(resp);
              setTimeout(()=>{
                $('[href="#collapseExample"]').click();
                this.refreshData()
              },2000)
              return Gen.info(resp.message, "success", 1000).then(()=>{
                this.$router.push({name:this.Name,params:{id:this.$route.params.id}})
              })
            }
          },"POST")
        },  
    },
    watch:{
    }

};
</script>
<template>
<div class="container-fluid">
    <PageTitle></PageTitle>
   <div class="topnavBackoffice">
        <router-link :to="{name:'ProjectHome',params:{id:$route.params.id}}" >Beranda</router-link>
        <router-link :to="{name:'ProjectProducts',params:{id:$route.params.id}}">Produk</router-link>
        <router-link :to="{name:'ProjectAbout',params:{id:$route.params.id}}">Tetang Kami</router-link>
        <a href="javascript:;" class="dropdown_bo">Artikel & Progres
          <ul class="dropdown_menu">
            <li><router-link :to="{name:'ProjectNews',params:{id:$route.params.id}}">Artikel</router-link></li>
            <li><router-link :to="{name:'ProjectProgress',params:{id:$route.params.id}}">Perkembangan Proyek</router-link></li>
          </ul>
        </a>
        <router-link class="active" :to="{name:'ProjectContact',params:{id:$route.params.id}}">Lokasi & Kontak</router-link>
    </div>

    <div class="row">
            <!-- Start Contact -->
                <div class="col-md-12 col-lg-12 col-sm-12 col-xs-12">
                    <div class="card">
                        <div class="card-body">
                            <h5 class="card-title">LOCATION & CONTACT</h5>
                            <VForm @resp="submitForm" method="post">
                            <div class="row">
                                <div class="col-md-12 info"></div>
                                <div class="col-md-6">
                                    <BoField name="sc_title" v-model="contact.sc_title" mandatory></BoField>
                                    <BoField name="sc_sub_title" v-model="contact.sc_sub_title" mandatory></BoField>
                                  </div>
                                <div class="col-md-12">
                                    <input type="hidden" name="type" value="editContact">
                                    <BoField name="sc_desc" mandatory :label="'Embed Script Maps'">
                                        <textarea name="content" rows="5" class="form-control" v-model="contact.sc_desc"></textarea>
                                    </BoField>
                                </div>
                                <div class="col-md-12 text-right"><button type="submit" class="btn btn-rounded btn-info btn-loading">Update Contact</button></div>
                            </div>
                            </VForm>
                        </div>
                    </div>
                </div>
            <!-- End Contact -->
        </div>
</div>
</template>